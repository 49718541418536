import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';

const PageContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

const AboutSection = styled.div`
    flex: 1;
    min-width: 300px;
    padding-right: 20px;
`;

const PRSection = styled.div`
    flex: 1;
    min-width: 300px;
    padding-left: 20px;
`;

const PRTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        text-align: left;
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }
`;

const CountdownContainer = styled.div`
    text-align: center;
    font-size: 36px;
    font-family: 'Digital-7', sans-serif;
    color: #ff4500;
    margin-top: 20px;
`;

const Image = styled.img`
    display: block;
    margin: 0 auto;
    width: 60%;
    margin-bottom: 20px;
`;

const RunningInterestPage = () => {
    const [content, setContent] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch('/Information/runningContent.json')
            .then(response => response.json())
            .then(data => setContent(data))
            .catch(error => console.error('Error fetching content:', error));

        fetch('/Information/interests.json')
            .then(response => response.json())
            .then(data => {
                const runningInterest = data.find(item => item.id === 'running');
                if (runningInterest) {
                    setImages(runningInterest.images);
                }
            })
            .catch(error => console.error('Error fetching images:', error));
    }, []);

    if (!content) {
        return <div>Loading...</div>;
    }

    const renderer = ({ days, hours, minutes, seconds }) => (
        <CountdownContainer>
            {days}d {hours}h {minutes}m {seconds}s
        </CountdownContainer>
    );

    return (
        <PageContainer>
            <Title>My Running Journey</Title>

            <Image src={content.image} alt="Running" />

            <ContentContainer>
                <AboutSection>
                    <h2>About My Journey</h2>
                    <p>{content.journey}</p>
                </AboutSection>

                <PRSection>
                    <h2>Personal Records (PRs)</h2>
                    <PRTable>
                        <tbody>
                        <tr>
                            <th>Event</th>
                            <th>Time</th>
                        </tr>
                        <tr>
                            <td>800m</td>
                            <td>{content.prs['800m']}</td>
                        </tr>
                        <tr>
                            <td>1600m</td>
                            <td>{content.prs['1600m']}</td>
                        </tr>
                        <tr>
                            <td>3200m</td>
                            <td>{content.prs['3200m']}</td>
                        </tr>
                        <tr>
                            <td>5k</td>
                            <td>{content.prs['5k']}</td>
                        </tr>
                        <tr>
                            <td>Half Marathon</td>
                            <td>{content.prs['halfMarathon']}</td>
                        </tr>
                        <tr>
                            <td>Marathon</td>
                            <td>{content.prs['marathon']}</td>
                        </tr>
                        </tbody>
                    </PRTable>
                </PRSection>
            </ContentContainer>

            <CountdownContainer>
                <h3>Countdown to {content.nextEventName}</h3>
                <Countdown date={new Date(content.nextEventDate)} renderer={renderer} />
            </CountdownContainer>
        </PageContainer>
    );
};

export default RunningInterestPage;
