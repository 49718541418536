import { Container, Typography, Avatar } from '@mui/material';
import styled from "styled-components";

const AboutSection = styled.div`
    padding: 50px 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const AboutSectionComponent = () => {
    return (
        <Container>
            <AboutSection>
                <Avatar
                    alt="Ryan Sweeney"
                    src="/assets/images/Headshot.jpg" // Update with your image path
                    sx={{ width: 150, height: 150, marginBottom: 2 }}
                />
                <Typography variant="h4" gutterBottom>
                    About Me
                </Typography>
                <Typography variant="body1" align="center">
                    I'm Ryan Sweeney, a recent graduate with dual degrees in Computer Science (AI specialization) and Data Science from Southern Methodist University. I have a passion for leveraging automation and AI to optimize company workflows. With expertise in software development, hardware integration, and bespoke automation consulting, I am dedicated to helping businesses harness the latest technology to improve efficiency and productivity. Let's work together to transform your business with innovative solutions.
                </Typography>
            </AboutSection>
        </Container>
    );
};

export default AboutSectionComponent;
