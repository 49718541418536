// Introduction.js
import React from 'react';
import {
    Headshot,
    Introduction,
    VerticalSpacer,
    TextField,
    TextH1,
    TextH3,
    Button,
    Child,
    CenteredContainer
} from '../styled/HomeStyles';

const IntroductionSection = () => (
    <CenteredContainer>
        <Introduction>
            <Child>
                <TextField>
                    <TextH1>Ryan Sweeney</TextH1>
                    <TextH3>Computer Science B.S. - A.I. Specialization</TextH3>
                    <TextH3>Data Science B.S.</TextH3>
                    <TextH3>Southern Methodist University</TextH3>
                    <Button href="mailto:rsweeney@smu.edu">Contact Me</Button>
                </TextField>
            </Child>
            <Child>
                <VerticalSpacer />
            </Child>
            <Child>
                <Headshot src="/assets/images/Headshot3.jpeg" alt="headshot" />
            </Child>
        </Introduction>
    </CenteredContainer>
);

export default IntroductionSection;
