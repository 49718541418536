// HomeStyles.js
import styled from 'styled-components';

export const CenteredContainer = styled.div`
  max-width: 1200px;
  margin: 0 20vw;
  padding: 20px;
`;

export const Headshot = styled.img`
  height: auto;
  width: 25rem;
  max-width: 100%;
  position: relative;
  margin-top: 2rem;
  margin-right: 2rem;
`;

export const LayoutColorful = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #4ABDAC;
  height: auto;
  position: relative;
  justify-content: left;
  align-items: start;
  align-content: start;
  padding-bottom: 40px;
  padding-top: 40px;
`;

export const LayoutColorful2 = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ECECEC;
  height: auto;
  position: relative;
  justify-content: left;
  align-items: start;
  align-content: start;
`;

export const Introduction = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const VerticalSpacer = styled.div`
  height: auto;
  width: 6rem;
`;

export const TextField = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  justify-content: left;
  max-width: 100%;
`;

export const TextField2 = styled.div`
  position: relative;
  justify-content: left;
`;

export const TextH1 = styled.div`
  font-weight: bold;
  font-size: 4rem;
  position: relative;
  color: #242424;
  display: flex;
  justify-content: left;
  align-items: start;
  max-width: 100%;
`;

export const TextH2 = styled.div`
  font-weight: bold;
  font-size: 2rem;
  position: relative;
  color: #242424;
  display: flex;
  justify-content: left;
  align-items: start;
`;

export const TextH2Light = styled.div`
  font-weight: bold;
  font-size: 2rem;
  position: relative;
  color: #FFF;
  display: flex;
  justify-content: left;
  align-items: start;
`;

export const TextH3 = styled.div`
  font-size: 1.5rem;
  position: relative;
  color: #242424;
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: start;
`;

export const TextH4 = styled.div`
  font-size: 16px;
  position: relative;
  color: #242424;
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: start;
  max-width: 40rem;
  padding-right: 2rem;
`;

export const TextH4Light = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5rem;
  font-size: 16px;
  position: relative;
  color: #FFF;
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: start;
  max-width: 40rem;
`;

export const TextH5Color = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #4ABDAC;
  display: flex;
  justify-content: left;
  align-items: start;
  width: 100%;
`;

export const Button = styled.a`
  text-decoration: none;
  font-size: 1.5rem;
  position: relative;
  top: 1rem;
  color: #fff;
  display: inline-block;
  justify-content: left;
  padding: 0.5rem 2rem;
  background-color: #4ABDAC;
`;

export const Button2 = styled.a`
  text-decoration: none;
  font-size: 1.5rem;
  position: relative;
  left: 0;
  top: 1rem;
  color: #242424;
  display: inline-block;
  justify-content: left;
  padding: 0.5rem 2rem;
  background-color: #FFF;
`;

export const Child = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

export const SkillsSection = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  color: #242424;
`;

export const HardwareSkillsSection = styled.div`
  position: relative;
  margin: 0 20vw;
  justify-content: left;
  padding-right: 2rem;
  padding-top: 4rem;
`;

export const OrganizationsSection = styled.div`
  position: relative;
  justify-content: left;
  padding-right: 2rem;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const InterestsSection = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const AwardsSection = styled.div`
  position: relative;
  justify-content: left;
  padding-right: 2rem;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const MediaQueries = styled.div`
  @media (max-width: 992px) {
    ${TextH1} {
      font-size: 3rem !important;
    }
    ${TextH2} {
      font-size: 2rem !important;
    }
    ${TextH3} {
      font-size: 1.25rem !important;
    }
    ${TextH4} {
      font-size: 0.8rem !important;
    }
    ${TextH5Color} {
      font-size: 1rem !important;
    }
    ${Button} {
      font-size: 90% !important;
    }
    ${Button2} {
      font-size: 90% !important;
    }
    ${Headshot} {
      margin-right: 4rem;
    }
    ${CenteredContainer} {
        margin: 0 10vw;
    }

  }
`;
