import './App.css';
import NavBar from "./components/NavBar";
import Footer from "./components/footer";
import {Routes, Route, useLocation} from 'react-router-dom';
import Home from "./pages/Home";
import CustomComputers from "./pages/CustomComputers";
import {useEffect} from "react";
import {logPageView} from "./Analytics";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RunningInterestPage from "./pages/runningInterestPage";
import ProjectsPage from "./pages/ProjectsPage";
import DefaultProjectDetailPage from "./pages/DefaultProjectPage";
import SolutionsPage from "./pages/SolutionsPage";
import DefaultInterestPage from "./pages/defaultInterstPage";
// import TestPage from "./pages/TestPage";

function App() {
    const location = useLocation()

    useEffect(() => {
        logPageView()
    }, [location]);
  return (
        <>
            <NavBar/>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/custom-computers" element={<CustomComputers/>}/>
                <Route exact path={"/solutions"} element={<SolutionsPage/>}/>
                <Route path="/interest/running" element={<RunningInterestPage />} />
                <Route path={"/interest/:id"} element={<DefaultInterestPage/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/projects" element={<ProjectsPage/>} />
                <Route path="/projects/:projectID" element={<DefaultProjectDetailPage/>} />
                <Route path="/christmas-card" element={<PrivacyPolicy />} />
                {/*<Route path={"/test"} element={<TestPage/>} />*/}
            </Routes>
            <Footer/>
        </>
  );
}

export default App;
